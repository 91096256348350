//i18n-setup.js
// import Vue from 'vue'
import Vue from 'vue'
import VueI18n from 'vue-i18n'


Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: '', // 设置语言环境
  fallbackLocale: 'zh',
  silentTranslationWarn: true,
  messages: {
    // 'en': messages
  } // 设置语言环境信息
});


const loadedLanguages = []; // 我们的预装默认语言

function setI18nLanguage (lang) {
  localStorage.setItem('lang',lang)
  i18n.locale = lang;
  return lang
}


export function loadLanguageAsync (lang) {
  if (i18n.locale !== lang) {
    if (!loadedLanguages.includes(lang)) {
      return import(/* webpackChunkName: "lang-[request]" */ `@/assets/languages/${lang}`).then(msgs => {
        i18n.setLocaleMessage(lang, msgs.default);
        loadedLanguages.push(lang);
        return setI18nLanguage(lang)
      })
    }
    return Promise.resolve(setI18nLanguage(lang))
  }
  return Promise.resolve(lang)
}

import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import 'element-ui/lib/theme-chalk/display.css';
import router from './router'
import {i18n, loadLanguageAsync} from './setup/i18n-setup'

Vue.config.productionTip = false;

new Vue({
  data (){
    return {
      locale:'zh'
    }
  },
  mounted (){
    let lang = localStorage.getItem('lang');
    if(lang) this.locale = lang;
    loadLanguageAsync(this.locale);
  },
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')

import Vue from "vue";
import VueRouter from "vue-router";

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

//获取原型对象上的replace函数
const originalReplace = VueRouter.prototype.replace;
//修改原型对象中的replace方法
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
};


Vue.use(VueRouter);

const routes = [
  //首页
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "sport" */ "@views/index/index.vue"),
  },
  {
    path: "/aboutUs",
    name: "aboutUs",
    component: () => import(/* webpackChunkName: "sport" */ "@views/aboutUs/aboutUs.vue"),
  },
  {
    path: "/findBeans",
    name: "Home",
    component: () => import(/* webpackChunkName: "sport" */ "@views/searchBeans/searchBeans.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  routes,
  scrollBehavior (){
    return { x: 0, y: 0 }
  }
});

export default router;
